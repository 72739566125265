<template>
<div id="cfo">
    <section class="hero-section pt-100 background-img" >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-6">
                    <div class="hero-content-left text-white ">
                        <!--<span class="text-uppercase h6">Customer First Priority</span>-->
                        <h1 class="text-white font-weight-bold">I vantaggi per i CFO</h1>
                        <p class="lead">Contratti trasparenti, meno burocrazia e ottimizzazione del budget: scopri l’offerta DaaS di Noltech.</p>

                        <button class="configuration-btn" @click="selectDevice(selectedDevice)">Configura i tuoi device</button>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="hero-animation-img">
                        <img src="img/viaggiatore2.png" alt="l'offerta DaaS di Noltech per i CFO" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="promo-section mt-5 ">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                        <div class="flip-div">
                            <div class="flip-main">
                                <div class="front">
                                    <div class=" single-promo-1">
                                        <div class="card-body text-center pb-2" style="min-height: 250px;">
                                            <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                            <h4 class="card-title numcard">1.850</h4>
                                            <p class="card-text">
                                                Aziende clienti*
                                            </p>
                                            <p>
                                                (Elmec 2021)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="back">
                                    <div class="single-promo-1" style="min-height: 250px">
                                        <div class="card-body text-center">
                                            <p class="card-text">* Registriamo un tasso di soddisfazione utenti sulla “first call resolution rate” superiore al 90%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 250px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">27</h4>
                                        <p class="card-text">
                                            Paesi serviti tramite noleggio diretto*
                                        </p>
                                        <p>
                                            (Elmec 2021)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 250px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Gestiamo direttamente contrattualistica e forniture in tutti i paesi dell’Unione Europea.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 250px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">28%</h4>
                                        <p class="card-text">
                                            Della spesa ICT è dedicato a dispositivi e servizi IT*
                                        </p>
                                        <p>
                                            (Gartner 2021)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 250px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Il canone di Noltech include sia il dispositivo che i servizi a esso dedicati ottimizzando tempi e riducendo la complessità.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 200px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">69%</h4>
                                        <p class="card-text">
                                            Aziende che nell’ultimo anno hanno aumentato le dotazioni hardware*
                                        </p>
                                        <p>
                                            (School of Management del Politecnico di Milano 2020)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 250px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Il modello DaaS ti consente di ampliare il tuo parco IT in tempi rapidi e a un costo fisso e predicibile con scadenze allineabili.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-8">
                    <div class="section-heading text-center  mb-5">
                        <h2>Trasparenza e flessibilità</h2>
                        <p class="lead">
                            Con quanti interlocutori ti devi interfacciare durante una collaborazione? Per non parlare di quando devi valutarne la salute finanziaria o accreditarli come fornitori. Quanto si allunga il processo decisionale? Noi di Noltech vogliamo semplificare il più possibile questo percorso. Siamo il tuo unico punto di riferimento e gestiamo direttamente sia le questioni amministrative che quelle tecniche, riducendo al massimo burocrazia e asterischi. Mettici alla prova!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="promo-section ptb-60">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>I punti di forza del DaaS di Noltech</h2>
                        <p class="lead">
                            Siamo il tuo unico compagno di viaggio: ci occupiamo di tutti gli aspetti della collaborazione, dalla parte economica e contrattuale fino all’assistenza tecnica.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                            <img src="/img/trasparente.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>TRASPARENTE</h5>
                        <p>Contratto di due pagine, senza asterischi e senza vincoli</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                           <img src="/img/semplice.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>SEMPLICE</h5>
                        <p>Siamo il tuo unico interlocutore in Italia e all’estero</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                            <img src="/img/flessibile.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>FLESSIBILE</h5>
                        <p>Modifica il contratto per rispondere alle nuove esigenze</p>
                    </div>
                </div>
            </div>
        </div>
    </section>




    <section id="noltechStart" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>L’offerta Noltech Start</h2>
                        <p> Massimizza l’investimento IT con un contratto che ad un <strong class="font-weight-bold"> canone fisso</strong> ti offre non solo i dispositivi di cui hai bisogno, ma anche servizi di gestione di prima qualità. </p>
                        <div class="single-feature mb-4 mt-5">
                            <div class="icon-box-wrap d-flex align-items-center mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/ultimaGenerazione.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Device in pronta consegna </strong> –  Grazie alla partnership con i principali vendor del mercato, ti forniamo dispositivi di ultima generazione direttamente dai nostri magazzini, riducendo al minimo i tempi di consegna e <strong class="font-weight-bold"> annullando qualsiasi tipo di intermediazione</strong>.</p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/staging.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Staging personalizzato</strong> –  I nostri tecnici si occuperanno della configurazione dei dispositivi in base alle indicazioni della tua azienda. In questo modo i tuoi collaboratori li riceveranno già <strong class="font-weight-bold"> pronti per essere utilizzati</strong>.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/cyberSicuro.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Cybersecurity integrata</strong> –  La sicurezza non è un optional e anche i singoli device devono essere attrezzati per un primo livello di protezione. Nel canone di Noltech è incluso anche un servizio di  <strong class="font-weight-bold"> cybersecurity avanzato</strong> per difendere i dispositivi e i dati aziendali.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/myelmecBlu.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> MyElmec </strong>–  Monitora i costi e pianifica gli investimenti futuri. Dalla piattaforma MyElmec avrai una <strong class="font-weight-bold"> visione completa</strong> della spesa relativa ai singoli device e in più potrai accedere in qualsiasi momento a tutti i dettagli amministrativi e contrattuali.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img src="img/zaino.svg" alt=" L’offerta Noltech Start per il Device as a Service" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>





    <section id="personalizzaOfferta" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img src="img/mappa.svg" alt=" I servizi di gestione dei dispositivi Noltech" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>Arricchisci il tuo pacchetto base</h2>
                        <p>La nostra offerta per il DaaS è <strong class="font-weight-bold"> modulabile</strong> e totalmente personalizzabile con dei servizi aggiuntivi applicabili a tutto il parco IT oppure solo a specifici dispositivi:</p>
                        <div class="single-feature mb-4 mt-5">
                            <div class="icon-box-wrap d-flex align-items-center mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/smartRollout.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Smart Rollout </strong>– La maggior parte delle nostre attività sono legate alle tecnologie e un rinnovo del parco IT può rallentare l’operatività. Con il servizio Smart Rollout non dovrai più preoccuparti delle interruzioni di business: i nostri tecnici sostituiranno <strong class="font-weight-bold"> on-site</strong> i tuoi dispositivi <strong class="font-weight-bold"> in meno di 20 minuti</strong>. </p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/wiping.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Blocco remoto e wiping</strong> – Essere conformi alle normative sulla protezione dei dati e sulla sicurezza è una criticità per molte aziende. In caso di necessità possiamo bloccare da remoto i dispositivi che non verranno più utilizzati e al termine della collaborazione ci occuperemo della <strong class="font-weight-bold"> cancellazione certificata dei dati</strong>. </p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/ipergaranzia.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Ipergaranzia Elmec </strong> – Tra i plus di Noltech ti proponiamo un servizio di manutenzione e riparazione dei device che va oltre alla garanzia standard. In più, grazie alla disponibilità nei nostri magazzini, consegniamo agli utenti dei dispositivi sostitutivi pre-configurati per garantire la continuità delle loro attività.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/office365.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Licenze Office 365 </strong>– Saremo il tuo interlocutore anche per le licenze Office. Tramite la partnership con Microsoft, ci occuperemo delle licenze per i tuoi dispositivi, sollevandoti da tutte le incombenze contrattuali.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container"><div class="row  justify-content-center ptb-60"><a @click="richiediPreventivo" class="btn solid-btn">Configura i tuoi device</a></div></div>
        </div>
    </section>



    <div id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <!--<span class="text-uppercase color-secondary sub-title">Best features</span>-->
                        <h2>Ti semplifichiamo la vita</h2>
                        <p>Tenere il conto di tutti i fornitori di un’azienda è un compito non da poco, almeno per quanto riguarda il lato device IT, vorremmo semplificarti la vita.  </p>

                    </div>
                </div>
            </div>

            <!--feature new style start-->
            <div class="row row-grid align-items-center">
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-1 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Unico interlocutore</h5>
                            <p class="mb-0">  Il nostro punto di forza è la semplicità: saremo il tuo unico punto di riferimento per tutte le necessità tecniche e amministrative, anche all’estero.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-2 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Controllo totale  </h5>
                            <p class="mb-0">Dalla piattaforma MyElmec puoi accedere alle informazioni contrattuali e monitorare lo status dei singoli device e i costi di gestione.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-3 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Zero sorprese </h5>
                            <p class="mb-0">Riduciamo la burocrazia e ti proponiamo un contratto chiaro e trasparente, senza vincoli e asterischi. Gestiamo direttamente anche la fatturazione per le sedi negli altri paesi. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="position-relative" style="z-index: 10;">
                        <img alt="I vantaggi Noltech per il CFO" src="img/cfo.svg" class="img-center img-fluid">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-4 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Ottimizza l’investimento IT</h5>
                            <p class="mb-0">Ti offriamo un canone fisso dilazionato nel tempo che non inciderà sulle spese in conto capitale e ti permetterà di ridurre il cash flow.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-5 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Flessibilità  </h5>
                            <p class="mb-0">Decidi tu per quanto tempo durerà il nostro viaggio insieme, da una settimana a cinque anni. E se ci ripensi, puoi recedere il contratto senza spese.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-6 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Wiping certificato</h5>
                            <p class="mb-0">Riconsegna i dispositivi senza pensieri: al termine della collaborazione, ci occupiamo dello smaltimento dei device e della cancellazione certificata dei dati.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--feature new style end-->
        </div>
    </div>





    <section id="tabella" class="package-section gray-light-bg ptb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="section-heading text-center mb-5">
              <h2>Perchè scegliere Noltech </h2>
              <p class="lead">
                  In Noltech abbiamo pensato a un modello DaaS trasparente che semplifichi la vita dei nostri clienti.
              </p>
                <p class="lead">
                    Mettici alla prova,<strong class="color-primary font-weight-bold"> i nostri contratti sono senza asterischi!</strong>
                </p>
            </div>
          </div>
        </div>
        <table class="table ">
          <thead class="table-info">
          <tr>

            <th><strong class=" font-weight-bold">Servizi più richiesti dalle aziende</strong></th>
            <th style="text-align: center"><strong class=" font-weight-bold">NolTech</strong>​​</th>
            <th style="text-align: center"><strong class=" font-weight-bold">Gli altri</strong></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td> Unico Interlocutore </td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center">

              <img src="/img/close.svg" width="30" alt="check" class="img-fluid">
              <img src="/img/information.svg" width="16px" v-tooltip.right="'I contratti di noleggio coinvolgono spesso due o più società diverse per gli aspetti tecnici ed amministrativi. Chi è il tuo vero interlocutore?'">
            </td>

          </tr>
          <tr>
            <td>Ci ripenso: restituisci la tecnologia a zero spese</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid">
              <img src="/img/information.svg" width="16px" v-tooltip.right="'Fai attenzione alle condizioni di restituzione di uno o più beni a fine contratto: spesso nascondono clausole vincolanti'">
            </td>

          </tr>
          <tr>
            <td>Contratto aperto e trasparente</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img src="/img/information.svg" width="16px"
                                               v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'">
            </td>

          </tr>
          <tr>
            <td>Tacito Rinnovo</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'"
            >
            </td>

          </tr>
          <tr>
            <td>Servizio EU</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Sei un business europeo/multinazionale: ti occorre una fatturazione separabile su più country. Con altre società avrai più contratti e dovrai gestire il “transfer price.'">
            </td>

          </tr>
          <tr>
            <td>Flessibilità</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Se il tuo business è scalabile nel tempo e vuoi aggiungere device di volta in volta, possono garantirti più lotti noleggiati in tempi diversi?'">
            </td>

          </tr>
          <tr>
            <td>Intervento nelle 24 h</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style="margin-right: 14px" src="/img/question-mark.svg" width="30"
                                                alt="check"
                                                class="img-fluid"></td>

          </tr>
          <tr>
            <td>EmbeddedCyberSecurity</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style="margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"></td>

          </tr>
          <tr>
            <td>Competenza Tecnica certificata</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style=" margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"></td>
          </tr>

          </tbody>
        </table>
      </div>
    </section>




</div>
</template>
<script>
    export default {
        name: 'cfo'
    }
</script>
<style scoped>
</style>



<script>
    import HubspotSignup from '../HubspotForm'
    import {commonMixins} from '../../mixins/common-mixins'
    import Multiselect from '../global/Multiselect'
    import MyInput from '../global/MyInput'

    export default {
        name: 'Home',
        mixins: [commonMixins],
        components: {MyInput, Multiselect, HubspotSignup},
        watch: {},
        data () {
            return {
                selectedDevice: '',
                deviceQuantity: null
            }
        },
        mounted: function () {
            this.goTopPage()
        },
        methods: {
            selectDevice: function (device) {
                this.$router.push({
                    name: 'Configurator',
                    params: {
                        deviceType: device,
                        deviceQuantity: this.deviceQuantity
                    }
                })
            },
            goToRoute: function (route) {
                this.$router.push({
                    name: route,
                })
            }
        }
    }
</script>

<style lang="less">
    #cfo {
        .configuration-btn {
            margin-top: 20px;
            width: 240px;
            height: 40px;
            outline: none;
            background: #F3F7FA 0% 0% no-repeat padding-box;
            border: 1px solid #2C5593;
            border-radius: 25px;
            opacity: 1;
            color: #2C5593;
            font-size: 16px;
            font-weight: bold;
            padding-top: 4px;
        }
        .btn {
            cursor: pointer;
        }

        .card-body img{
            height: 110px !important;
        }
        .flip-div{
            cursor: pointer;
        }
        .flip-div:hover .back,.flip-div.hover .back {
            -webkit-transform: rotateY(0deg);
            -moz-transform: rotateY(0deg);
            -o-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            transform: rotateY(0deg);
        }
        .flip-div:hover .front,.flip-div.hover .front {
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
        .flip-main{
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -ms-transition: 1s;
            -moz-transition: 1s;
            -moz-transform: perspective(1000px);
            -moz-transform-style: preserve-3d;
            -ms-transform-style: preserve-3d;
            transition: 1s;
            transform-style: preserve-3d;
            position: relative;
        }
        .front{
            position: relative;
            -webkit-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            z-index: 2;
            margin-bottom: 30px;
        }
        .back{
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform: rotateY(-180deg);
            -moz-transform: rotateY(-180deg);
            -o-transform: rotateY(-180deg);
            -ms-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
            min-height: 250px;
            background-color: #2C5693;
            color: white;
            border-radius: 1rem;

        }
        .back ul li a{
            font-size: 18px;
        }
        .front,.back {
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -moz-transition: 1s;
            -moz-transform-style: preserve-3d;
            -o-transition: 1s;
            -o-transform-style: preserve-3d;
            -ms-transition: 1s;
            -ms-transform-style: preserve-3d;
            transition: 1s;
            transform-style: preserve-3d;
        }
        .card-flip{
            border: 1px solid #eff2f7;
            border-radius: 1rem;
            box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
        }
        .numcard{
            font-weight: bold;
        }
        .single-promo-1{
            min-height: 250px;
        }
    }
</style>
